import type { Config } from "tailwindcss";

export const COLORS = {
  white: "#FFFFFF",
  "dark-gray": "#1C212E",
  "grey-mid": "#73738C",
  "gray-opacity-30": "rgba(128, 128, 128, 0.3)",
  "light-yellow": "#FFF96B",
  "light-gray": "#EAF0F6",
  "soft-gray": "#E0E0E0",

  purple: "#7747EC",
  black: "#000000",
} as const;

const config: Config = {
  content: [
    "./src/pages/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/components/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/app/**/*.{js,ts,jsx,tsx,mdx}",
  ],
  theme: {
    extend: {
      colors: COLORS,
      transitionTimingFunction: {
        "in-expo": "cubic-bezier(0.95, 0.05, 0.795, 0.035)",
        "out-expo": "cubic-bezier(0.19, 1, 0.22, 1)",
      },
      backgroundImage: {
        "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
        "gradient-conic": "conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))",
        "gradient-to-bottom": "linear-gradient(to bottom, #1C212E 0%, rgba(28, 33, 46, 0) 100%)",
        "gradient-to-top": "linear-gradient(to top, #1C212E 0%, rgba(28, 33, 46, 0) 100%)",
      },
      gridTemplateColumns: {
        "40": "repeat(40, minmax(0, 1fr))",
        "74": "repeat(74, minmax(0, 1fr))",
      },
      gridColumn: {
        "span-11": "span 11 / span 11",
        "span-10": "span 10 / span 10",
      },
      minHeight: {
        "67": "16.875rem",
        "70": "17.622rem",

        "76": "19.06rem",
        "82": "20.625rem",

        "84": "20.90rem",
        "85": "21rem",
        "87": "21.75rem",

        "94": "23.65rem",
        "95": "23.75rem",

        "98": "24.625rem",
        "99": "24.875rem",
        "109": "27.722rem",
        "110": "25rem",
        "111": "25.5rem",
        "120": "30rem",
        "140": "35rem",
        "160": "40rem",
        "260": "65rem",
      },
      boxShadow: {
        soft: "21px 23px 23px 3px rgba(0,0,0,0.1)",
        imageCard: "-13px 17px 40px 0px rgba(0,0,0,0.4)",
      },
      width: {
        "18": "4.5rem",
        "128": "32rem",
        "680": "42.75rem",
        "700": "43.75rem",
        "800": "50rem",
      },
      height: {
        "128": "32rem",
        "132": "33.125rem",
        "150": "37.5rem",
      },
      spacing: {
        "7": "1.75rem",
      },
      scale: {
        "65": ".65",
        "70": ".70",
      },
      padding: {
        "19": "4.6875rem",
      },
      keyframes: {
        shake: {
          "0%, 100%": { transform: "rotate(0deg)" },
          "25%": { transform: "rotate(-5deg)" },
          "75%": { transform: "rotate(3deg)" },
        },
        floatFront: {
          "0%": { transform: "translate(0, 0) rotate(0deg)" },
          "50%": { transform: "translate(30px, -20px) rotate(2deg)" },
          "100%": { transform: "translate(0, 0) rotate(0deg)" },
        },
        floatMiddle: {
          "0%": { transform: "translate(0, 0) rotate(0deg)" },
          "50%": { transform: "translate(10px, -7px) rotate(1deg)" },
          "100%": { transform: "translate(0, 0) rotate(0deg)" },
        },
        floatBack: {
          "0%": { transform: "translate(0, 0) rotate(0deg)" },
          "50%": { transform: "translate(5px, -5px) rotate(0.2deg)" },
          "100%": { transform: "translate(0, 0) rotate(0deg)" },
        },
        "fade-in": {
          "0%": { opacity: "0" },
          "100%": { opacity: "1" },
        },
        "fade-in-up": {
          "0%": { opacity: "0", transform: "translateY(10px)" },
          "100%": { opacity: "1", transform: "translateY(0)" },
        },
        "fade-in-down": {
          "0%": { opacity: "0", transform: "translateY(-10px)" },
          "100%": { opacity: "1", transform: "translateY(0)" },
        },
        "fade-in-left": {
          "0%": { opacity: "0", transform: "translateX(-10px)" },
          "100%": { opacity: "1", transform: "translateX(0)" },
        },
        "fade-in-right": {
          "0%": { opacity: "0", transform: "translateX(10px)" },
          "100%": { opacity: "1", transform: "translateX(0)" },
        },
        marquee: {
          "0%": { transform: "translateX(0)" },
          "100%": { transform: "translateX(-100%)" },
        },
        "marquee-reverse": {
          "0%": { transform: "translateX(-100%)" },
          "100%": { transform: "translateX(0)" },
        },
      },
      animation: {
        "icon-shake": "shake 0.3s ease-in-out infinite",
        "icon-float-front": "floatFront 5.5s ease-in-out infinite",
        "icon-float-middle": "floatMiddle 4.7s ease-in-out infinite",
        "icon-float-back": "floatBack 6.1s ease-in-out infinite",
        "fade-in": "fade-in 0.5s ease-out",
        "fade-in-up": "fade-in-up 0.5s ease-out",
        "fade-in-down": "fade-in-down 0.5s ease-out",
        "fade-in-left": "fade-in-left 0.5s ease-out",
        "fade-in-right": "fade-in-right 0.5s ease-out",
        marquee: "marquee 25s linear infinite",
        "marquee-reverse": "marquee-reverse 25s linear infinite",
      },
      transitionProperty: {
        transform: "transform",
      },
    },
  },
  plugins: [
    function ({ addUtilities }: { addUtilities: any }) {
      const newUtilities = {
        ".scrollbar-hide": {
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
      };
      addUtilities(newUtilities);
    },
  ],
};
export default config;
